<template>
    <ASelect
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Kabupaten/Kota"
        allow-clear
        show-search
        not-found-content="Tidak ditemukan"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="d in state.data" :key="d.id" :label="d.name">
            <span v-html="highlight(d.name)"></span>
        </ASelectOption>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, pickBy } from 'lodash'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        group_role_id: {
            type: [Number, String],
            default: null,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: `/api/filter/group-role-kabupaten`,
            loading: false,
            data: [],
            params: {
                q: '',
            },
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(`${state.endpoint}/${props.group_role_id}`, { params: pickBy(state.params) })
                .then(({ data }) => {
                    state.data = data
                })
                .finally(() => {
                    state.loading = false
                })
        }

        onMounted(() => {
            fetchData()
        })

        watch(() => props.group_role_id, () => {
            fetchData()
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 500))

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
        }
    },
}
</script>